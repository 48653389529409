import React, { useEffect, useState } from "react";
import { Button, Form, Input, Checkbox, message } from "antd";
import "../css/Auth.css";
import Success from "../components/Modal/Success";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";
import axiosClient from "../utils/axiosClient";
import StoreChannelValidateAndRequiredModal from "../components/StoreChannelValidateAndRequiredModal/StoreChannelValidateAndRequiredModal";
import primaryBareBgImg from "../images/primary-bare_bg.png";
import primaryFrameImg from "../images/primary_frame.png";
import HeadingTitle from "../components/HeadingTitle/HeadingTitle";
import slogan from "../images/slogan.png";
import sgChannel from "../pdf/Haleon CNY 2024 (SG)_Consumer TC_Haleon Start a Healthy New Year.pdf";
import { validatePhoneNumber } from "../utils/validation";

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [proceed, setProcced] = useState(false);
  const [form] = Form.useForm();
  const [referId] = useLocalStorage("referCode");
  const [tempOtp, setTempOtp] = useLocalStorage("tempOtp");
  const [loading, setLoading] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [error, setError] = useState(false);
  const [, setFullName] = useState("");
  const [showFullNameAsterisk, setShowFullNameAsterisk] = useState(true);
  const [showChannelRequiredModal, setShowChannelRequiredModal] =
    useState(false);

  const [searchParams] = useSearchParams();
  const routeChannel = searchParams.get("channel");
  const storeId = searchParams.get("storeId");
  const regexPhoneNum = /^(65[0-9]{8})$/;

  // auto set phone number if from login
  useEffect(() => {
    if (location.state?.phone) {
      form.setFieldsValue({ phone: "65" + location.state?.phone });
    }
  }, []);

  const validateChannel = async (channel) => {
    axiosClient
      .get(`/public/haleon-cny/campaigns/${channel}/validity`)
      .then((res) => {
        if (!res.data?.isValid) {
          setShowChannelRequiredModal(true);
        }
      })
      .catch((err) => {
        setShowChannelRequiredModal(true);
      });
  };

  useEffect(() => {
    if (routeChannel) {
      validateChannel(routeChannel);
    } else {
      setShowChannelRequiredModal(true);
    }
  }, []);

  const [pdfLink, setPdfLink] = useState();

  useEffect(() => {
    const getTnC = () => {
      setPdfLink(sgChannel);
    };
    getTnC();
  }, [routeChannel]);

  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      const register = {
        name: values.name,
        phone: values.phone,
        email: values.email,
        companyId:
          process.env.REACT_APP_REGION === "SG"
            ? process.env.REACT_APP_COMPANY_ID_SG
            : process.env.REACT_APP_COMPANY_ID_MY,
        storeId: storeId ? storeId : null,
        referralCode: referId ? referId : null,
        otp: tempOtp,
        channel: routeChannel ? String(routeChannel).toUpperCase() : null,
        promoterCode: "",
      };

      const res = await axiosClient.post("/auth/register-otp", register);
      if (res?.data?.success && res?.data?.token) {
        setTempOtp(null);
        localStorage.setItem("user-token", res.data.token);
        setProcced(true);
      }

      if (res?.data?.message) {
        message.error(res.data.message);
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Registration failed.";
      message.error(errorMessage);
      setLoading(false);
      setError(errorMessage);
      setErrorModal(true);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;

    if (event.target.name === "name") {
      setFullName(value);
      setShowFullNameAsterisk(!value);
    }
  };

  const regexName = /^[a-zA-Z ]+( [a-zA-Z ]+)*$/;

  return (
    <div
      style={{
        width: "100%",
        minWidth: "280px",
        minHeight: "100vh",
        backgroundImage: `url(${primaryBareBgImg})`,
        backgroundSize: "100%",
        backgroundRepeat: "repeat",
        backgroundColor: "red",
        backgroundPosition: "top center",
        position: "relative",
        paddingBottom: "auto",
        textAlign: "center",
        zIndex: "1",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${primaryFrameImg})`,
          backgroundSize: "92%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "absolute",
          zIndex: "-1",
        }}
      />
      <StoreChannelValidateAndRequiredModal
        open={showChannelRequiredModal}
        onCancel={() => navigate("/login" + window.location.search)}
      />
      <HeadingTitle />
      <img
        src={slogan}
        alt="slogan"
        style={{ width: "100%", zIndex: "1000", marginTop: "-8%" }}
      />
      <div>
        <h1
          className="title-font"
          style={{
            marginTop: "-3%",
          }}
        >
          Registration
        </h1>
        <hr className="underline-style" />
        <Form
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={(e) => console.error("submit fail", e.errorFields)}
          disabled={loading}
          aria-label="form"
          autoComplete="off"
          scrollToFirstError
          style={{ width: "85%", margin: "5% auto" }}
        >
          <Form.Item
            name="name"
            className="auth-text-color"
            hasFeedback
            rules={[
              {
                required: true,
                message: "this is required",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && value?.length > 0) {
                    if (regexName.test(value)) {
                      return Promise.resolve(true);
                    }
                    return Promise.reject(new Error("Invalid name format!"));
                  }
                  return Promise.resolve(true);
                },
              }),
            ]}
            style={{ marginBottom: "3%" }}
          >
            <div className="input-wrapper">
              <Input
                type="text"
                name="name"
                placeholder="Full Name"
                className="form-input"
                style={{ color: "red", height: "40px", borderRadius: "10px" }}
                required={true}
                onChange={handleInputChange}
              />
              {showFullNameAsterisk && (
                <label className="full-name-asterisk"></label>
              )}
            </div>
          </Form.Item>

          <Form.Item
            name="phone"
            className="auth-text-color"
            validateDebounce={500}
            hasFeedback
            style={{ marginBottom: "3%" }}
            rules={[
              {
                required: true,
                message: "this is required",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && value?.length > 0) {
                    if (regexPhoneNum.test(value)) {
                      return Promise.resolve(true);
                    }
                    return Promise.reject(
                      new Error("Invalid phone number format!")
                    );
                  }
                  return Promise.resolve(true);
                },
              }),
            ]}
          >
            <Input
              type="tel"
              name="phone"
              className="form-input"
              placeholder="Mobile Number"
              style={{ color: "red", height: "40px", borderRadius: "10px" }}
            />
          </Form.Item>

          <Form.Item
            name="email"
            className="auth-text-color"
            validateDebounce={500}
            style={{ marginBottom: "3%" }}
            hasFeedback
            rules={[{ type: "email", message: "Invalid email format!" }]}
          >
            <Input
              type="email"
              name="email"
              placeholder="Email"
              style={{ color: "red", height: "40px", borderRadius: "10px" }}
            />
          </Form.Item>

          <div
            className="text-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Checkbox
              required
              className="t-white"
              style={{ position: "relative", bottom: "8px" }}
            >
              <p
                className="font-14"
                style={{
                  lineHeight: "18px",
                  fontWeight: "400",
                  maxWidth: "100%",
                  textAlign: "justify",
                  margin: "auto",
                  color: "white",
                  marginTop: "5%",
                }}
              >
                I agree to Haleon's{" "}
                <a
                  className="t-white"
                  href={pdfLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "underline" }}
                >
                  Terms and Conditions
                </a>{" "}
                and the{" "}
                <a
                  className="t-white text-deco-underline"
                  target="_blank"
                  href={"https://www.privacy.haleon.com/en-sg/"}
                  rel="noreferrer"
                  style={{ textDecoration: "underline" }}
                >
                  Privacy Policy
                </a>
                .
              </p>
            </Checkbox>
          </div>
          <div
            className="text-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginTop: "3%" }}>
              <div
                className="text-center"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Checkbox
                  required
                  className="t-white"
                  style={{ position: "relative", bottom: "1rem" }}
                />
                <p
                  style={{
                    lineHeight: "14px",
                    fontWeight: "400",
                    maxWidth: "100%",
                    textAlign: "justify",
                    margin: "auto",
                    color: "white",
                    marginLeft: "10px",
                  }}
                >
                  I authorize Haleon to disclose my personal data* to selected
                  third parties to deliver value added services to me as a
                  Haleon Rewards member.
                </p>
              </div>
            </div>
          </div>
          <Form.Item>
            <Button
              htmlType="submit"
              block
              // loading={loading}
              style={{
                borderRadius: "5px",
                background: "#63BC46",
                border: "none",
                color: "white",
                height: "40px",
              }}
              className="font-16 font-weight-700 mt-3"
            >
              Register
            </Button>
          </Form.Item>
        </Form>
        <Success
          isModalOpen={proceed}
          setIsModalOpen={setProcced}
          title={"Successful!"}
          msg={"Registration Successful"}
          location={`/home${window.location.search}`}
        />
      </div>
    </div>
  );
};

export default Register;
