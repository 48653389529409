import React, { useEffect, useState } from "react";
import { Button, Modal, message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import primaryBgImg from "../images/testing.png";
import textLogo from "../images/textLogo.png";
import axiosClient from "../utils/axiosClient";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserEligibleCNYCampaignByChannelIfNotExist } from "../redux/campaign/campaignActions";
import { fetchUserProfileIfNotExist } from "../redux/user/userActions";

const Submissions = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [status, setStatus] = useState();
  const currentPage = location.pathname;
  const {
    userProfile,
    isLoading: userRLoading,
    error: userRError,
  } = useSelector((state) => state.userReducer);
  const {
    campaign,
    isLoading: camRLoading,
    error: camRError,
  } = useSelector((state) => state.campaignReducer);

  useEffect(() => {
    const _error = userRError || camRError;
    // must be string
    if (_error && typeof _error === "string") {
      message.error(_error);
    }
  }, [userRError, camRError]);

  // FETCH USER ELIBIGLE CAMPAIGN DETAILS
  useEffect(() => {
    if (userRError || camRError) {
      return;
    }

    if (!userRLoading && userProfile && !camRLoading && !campaign) {
      const userChannel = userProfile?.personalInfo?.registerSource?.channel;
      if (!userChannel) {
        console.error("user missing registerSource channel ");
        return;
      }
      dispatch(fetchUserEligibleCNYCampaignByChannelIfNotExist(userChannel));
    }
  }, [
    userProfile,
    userRLoading,
    camRLoading,
    campaign,
    dispatch,
    userRError,
    camRError,
  ]);

  useEffect(() => {
    dispatch(fetchUserProfileIfNotExist());
  }, []);

  useEffect(() => {
    const getReceiptSubmissions = async () => {
      if (!campaign?.id) {
        return;
      }
      try {
        const res = await axiosClient.get("/submissions", {
          params: { campaignId: campaign.id },
        });

        if (res && res.data && res.data.getFinalSubmissionInfo) {
          // console.log("submissions", res.data.getFinalSubmissionInfo);
          setSubmissions(res.data.getFinalSubmissionInfo);
        }
      } catch (e) {
        console.error("Error fetching submissions", e);
      }
    };
    getReceiptSubmissions();
  }, [campaign]);

  const handleReceiptClick = (item, displayStatus) => {
    setIsModalOpen(true);
    setSelectedReceipt(item);
    setStatus(displayStatus);
  };

  return (
    <div
      style={{
        minWidth: "100vw",
        minHeight: "100vh",
        backgroundImage: `url(${primaryBgImg})`,
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <div className="rewards-wrap">
        <div>
          <img className="header-text-img" src={textLogo} alt="" />
        </div>
        <div className="primary-buttons">
          <Button
            onClick={() => navigate("/rewards")}
            style={{
              backgroundColor:
                currentPage === "/rewards" ? "#63bc46" : "#BDBDBD",
            }}
            className="myButton"
          >
            My Rewards
          </Button>
          <Button
            onClick={() => navigate("/submissions")}
            style={{
              backgroundColor:
                currentPage === "/submissions" ? "#63bc46" : "#BDBDBD",
            }}
            className="myButton"
          >
            My Submissions
          </Button>
        </div>

        <div className="rewards-list">
          {submissions && submissions.length ? (
            <div>
              {submissions.map((item, index) => {
                const status =
                  item.getSubmissionInfo.receipts[0]?.status ||
                  item.getSubmissionInfo.status ||
                  "Unknown Status";

                let displayStatus;

                switch (status) {
                  case "PENDING":
                  case "IN_REVIEW":
                  case "PROCESSING":
                    displayStatus = "Pending";
                    break;
                  case "COMPLETED":
                  case "APPROVED":
                    displayStatus = "Approved";
                    break;
                  case "CANCELLED":
                  case "REJECTED":
                    displayStatus = "Rejected";
                    break;
                  default:
                    displayStatus = "Unknown Status";
                }

                return (
                  <div
                    key={index}
                    className="rewards-container"
                    style={{ margin: "3% auto" }}
                  >
                    <>
                      <div
                        className="submissions-info"
                        onClick={() => handleReceiptClick(item, displayStatus)}
                      >
                        <div
                          style={{
                            width: "25%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={item.imageUrl}
                            alt="receipt"
                            style={{ width: "60%", height: "60%" }}
                          />
                        </div>
                        <div
                          style={{
                            width: "50%",
                            paddingLeft: "2%",
                          }}
                        >
                          <p
                            className="text-left font-weight-600"
                            style={{ fontSize: "13px" }}
                          >
                            Receipt:{" "}
                            {item?.getSubmissionInfo?.receipts[0]?.invoiceNo ||
                              "-"}
                          </p>
                          <p className="text-left font-14 font-weight-400">
                            {item.getSubmissionInfo.createdAt
                              ? moment(item.getSubmissionInfo.createdAt).format(
                                  "DD MMM YYYY"
                                )
                              : "-"}
                          </p>
                          <p
                            className="text-left font-14 font-weight-600"
                            style={{
                              color:
                                displayStatus === "Pending"
                                  ? "#BBA802"
                                  : displayStatus === "Approved"
                                  ? "#35BF54"
                                  : displayStatus === "Rejected"
                                  ? "#C4113C"
                                  : "black",
                            }}
                          >
                            {displayStatus}
                          </p>
                        </div>
                        <div
                          style={{ width: "25%" }}
                          className="font-weight-700 font-16 text-center"
                        >
                          $
                          {item.getSubmissionInfo.totalReceiptAmount > 0
                            ? item.getSubmissionInfo.totalReceiptAmount
                            : "-"}
                        </div>
                      </div>
                    </>
                  </div>
                );
              })}
            </div>
          ) : (
            "No Receipt Submission Yet"
          )}
        </div>
        <div style={{ margin: "5% 10% 2% 10%" }}>
          <Button
            style={{
              width: "100%",
              textAlign: "center",
              borderRadius: "5px",
              background: "#63BC46",
              border: "none",
              color: "#FFF",
              fontWeight: "700",
              height: "40px",
            }}
            onClick={() => navigate("/home")}
          >
            Back
          </Button>
        </div>

        <Modal
          open={isModalOpen}
          centered
          header={null}
          footer={null}
          closable={false}
        >
          <div className="text-center">
            <img
              src={selectedReceipt?.imageUrl}
              alt="receipt_preview"
              style={{ width: "91px", height: "91px" }}
            />
            <p className="font-24 font-weight-700">{status}</p>
            <div className="submit-pending-modal-font">
              {(status === "Approved" || status === "Pending") && (
                <div
                  className="approve-info-container"
                  style={{
                    border: status === "Pending" && "2px solid #BBA802",
                  }}
                >
                  <p className="ml-2 font-14">
                    <b>Date Submitted</b>:{" "}
                    {selectedReceipt.getSubmissionInfo.createdAt
                      ? moment(
                          selectedReceipt.getSubmissionInfo.createdAt
                        ).format("DD MMM YYYY")
                      : "-"}
                  </p>
                  <p className="ml-2">
                    <b>Receipt Date</b>:{" "}
                    {selectedReceipt.getSubmissionInfo?.receipts[0].receiptDate
                      ? moment(
                          selectedReceipt.getSubmissionInfo?.receipts[0]
                            .receiptDate
                        ).format("DD MMM YYYY")
                      : " -"}{" "}
                  </p>
                  <p className="ml-2">
                    <span className="font-14 font-weight-700">
                      Receipt Amount:{" "}
                    </span>

                    {selectedReceipt.getSubmissionInfo.totalReceiptAmount > 0
                      ? selectedReceipt.getSubmissionInfo.totalReceiptAmount
                      : "-"}
                  </p>
                  <p className="ml-2">
                    <span className="font-14 font-weight-700">
                      Receipt Number:{" "}
                    </span>
                    {selectedReceipt?.getSubmissionInfo?.receipts[0]
                      ?.invoiceNo || " -"}
                  </p>
                </div>
              )}

              {status === "Rejected" && (
                <div>
                  <p>
                    Your submission have been rejected:
                    <br />
                    <span className="font-weight-700 font-12">
                      {selectedReceipt.getSubmissionInfo?.receipts[0]?.remark
                        .length > 0
                        ? selectedReceipt.getSubmissionInfo?.receipts[0]?.remark
                        : "Reason not found"}
                    </span>
                    <br />
                    Kindly upload your receipt again"
                  </p>
                </div>
              )}
            </div>
            {status === "Rejected" ? (
              <Button
                className="font-weight-500"
                style={{
                  borderRadius: "8px",
                  background: "#C4113C",
                  width: "90%",
                  height: "40px",
                  color: "white",
                }}
                onClick={() =>
                  navigate(
                    `/upload?prevCamEntryId=${selectedReceipt.getSubmissionInfo?.receipts[0]?.campaignEntryId}`
                  )
                }
              >
                Upload Again
              </Button>
            ) : (
              ""
            )}
            <Button
              style={{
                borderRadius: "8px",
                background: "#D4D4D4",
                width: "90%",
                height: "40px",
                marginTop: "3%",
              }}
              className="font-weight-500"
              onClick={() => setIsModalOpen(false)}
            >
              Back
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Submissions;
